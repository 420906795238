/* eslint-disable react/jsx-key */
import React, { useState } from 'react'
import { logSegEvent } from '../../lib/analytics'
import { BUTTON_CLICK } from '../../constants/analytics'

export default function FAQ() {
  const experiment = 'faqs'
  const variant = '1'

  const [openFaq, setOpenFaq] = useState(null)

  const icon = (index) => {
    return index === openFaq ? '-' : '+'
  }

  const faqClick = (index) => {
    if (index === openFaq) {
      setOpenFaq(null)
    } else {
      setOpenFaq(index)
      logSegEvent(BUTTON_CLICK, {
        experiment,
        variant,
        tag: index,
      })
    }
  }

  return (
    <div id="faq">
      <button onClick={() => faqClick(0)} className="btn">
        <p>{icon(0)}</p>
        <p>Who made Landslide?</p>
      </button>
      {openFaq === 0 && <p>{answers[0]}</p>}
      <button onClick={() => faqClick(1)} className="btn">
        <p>{icon(1)}</p>
        <p>What's the point of voting early?</p>
      </button>
      {openFaq === 1 && <p>{answers[1]}</p>}
      <button onClick={() => faqClick(2)} className="btn">
        <p>{icon(2)}</p>
        <p>What's the "Red Mirage" and why is in-person voting important?</p>
      </button>
      {openFaq === 2 && <p>{answers[2]}</p>}
      <button onClick={() => faqClick(3)} className="btn">
        <p>{icon(3)}</p>
        <p>Are there any states in which it’s better NOT to vote early?</p>
      </button>
      {openFaq === 3 && <p>{answers[3]}</p>}
      <button onClick={() => faqClick(4)} className="btn">
        <p>{icon(4)}</p>
        <p>What if I can’t vote early or in person?</p>
      </button>
      {openFaq === 4 && <p>{answers[4]}</p>}
      <button onClick={() => faqClick(5)} className="btn">
        <p>{icon(5)}</p>
        <p>Is voting in person dangerous during COVID?</p>
      </button>
      {openFaq === 5 && <p>{answers[5]}</p>}
      <button onClick={() => faqClick(6)} className="btn">
        <p>{icon(6)}</p>
        <p>Is it better to vote early in person or by mail?</p>
      </button>
      {openFaq === 6 && <p>{answers[6]}</p>}
      <button onClick={() => faqClick(7)} className="btn">
        <p>{icon(7)}</p>
        <p>Is it better to vote on November 3 in person or to vote early by mail?</p>
      </button>
      {openFaq === 7 && <p>{answers[7]}</p>}
      <button onClick={() => faqClick(8)} className="btn">
        <p>{icon(8)}</p>
        <p>I signed up to vote by mail, but now I want to vote in person.</p>
      </button>
      {openFaq === 8 && <p>{answers[8]}</p>}
      <button onClick={() => faqClick(9)} className="btn">
        <p>{icon(9)}</p>
        <p>Is early in-person absentee the same as early in-person?</p>
      </button>
      {openFaq === 9 && <p>{answers[9]}</p>}
      <button onClick={() => faqClick(10)} className="btn">
        <p>{icon(10)}</p>
        <p>Do you have any tips for voting in person?</p>
      </button>
      {openFaq === 10 && <p>{answers[10]}</p>}
      <button onClick={() => faqClick(11)} className="btn">
        <p>{icon(11)}</p>
        <p>Do you have any tips for voting by mail or absentee?</p>
      </button>
      {openFaq === 11 && <p>{answers[11]}</p>}
      <button onClick={() => faqClick(12)} className="btn">
        <p>{icon(12)}</p>
        <p> What if I encounter problems at the polling station?</p>
      </button>
      {openFaq === 12 && <p>{answers[12]}</p>}

      <button onClick={() => faqClick(13)} className="btn">
        <p>{icon(13)}</p>
        <p>Who's funding this and how can I support you?</p>
      </button>
      {openFaq === 13 && <p>{answers[13]}</p>}
      <button onClick={() => faqClick(14)} className="btn">
        <p>{icon(14)}</p>
        <p>What can I do after I've made sure I'm registered and invited a couple of friends?</p>
      </button>
      {openFaq === 14 && <p>{answers[14]}</p>}
      <button onClick={() => faqClick(15)} className="btn">
        <p>{icon(15)}</p>
        <p>Is my data safe?</p>
      </button>
      {openFaq === 15 && <p>{answers[15]}</p>}
      <button onClick={() => faqClick(16)} className="btn">
        <p>{icon(16)}</p>
        <p>All my friends already vote.</p>
      </button>
      {openFaq === 16 && <p>{answers[16]}</p>}
      <button onClick={() => faqClick(17)} className="btn">
        <p>{icon(17)}</p>
        <p>Are you going to spam me or my friends?</p>
      </button>
      {openFaq === 17 && <p>{answers[17]}</p>}
      <button onClick={() => faqClick(18)} className="btn">
        <p>{icon(18)}</p>
        <p>Is it time consuming?</p>
      </button>
      {openFaq === 18 && <p>{answers[18]}</p>}
      <button onClick={() => faqClick(19)} className="btn">
        <p>{icon(19)}</p>
        <p>How do you track my impact?</p>
      </button>
      {openFaq === 19 && <p>{answers[19]}</p>}
      <button onClick={() => faqClick(20)} className="btn">
        <p>{icon(20)}</p>
        <p>What are the Landslide target states?</p>
      </button>
      {openFaq === 20 && <p>{answers[20]}</p>}
      <button onClick={() => faqClick(21)} className="btn">
        <p>{icon(21)}</p>
        <p>How did you choose the Landslide target states?</p>
      </button>
      {openFaq === 21 && <p>{answers[21]}</p>}
      <button onClick={() => faqClick(22)} className="btn">
        <p>{icon(22)}</p>
        <p>What if I don't live in a Landslide target state?</p>
      </button>
      {openFaq === 22 && <p>{answers[22]}</p>}
      <button onClick={() => faqClick(23)} className="btn">
        <p>{icon(23)}</p>
        <p>How can I help get new voters in target states?</p>
      </button>
      {openFaq === 23 && <p>{answers[23]}</p>}
      <button onClick={() => faqClick(24)} className="btn">
        <p>{icon(24)}</p>
        <p>Can I use Landslide to help my friends vote Republican?</p>
      </button>
      {openFaq === 24 && <p>{answers[24]}</p>}
      <button onClick={() => faqClick(25)} className="btn">
        <p>{icon(25)}</p>
        <p>What’s the difference between my private link and my public link?</p>
      </button>
      {openFaq === 25 && <p>{answers[25]}</p>}
      <button onClick={() => faqClick(26)} className="btn">
        <p>{icon(26)}</p>
        <p>Can I use the same crew link to invite multiple friends?</p>
      </button>
      {openFaq === 26 && <p>{answers[26]}</p>}
      <button onClick={() => faqClick(27)} className="btn">
        <p>{icon(27)}</p>
        <p>Do you have any tips for inviting people?</p>
      </button>
      {openFaq === 27 && <p>{answers[27]}</p>}
      <button onClick={() => faqClick(28)} className="btn">
        <p>{icon(28)}</p>
        <p>Is there a Landslide intro video?</p>
      </button>
      {openFaq === 28 && <p>{answers[28]}</p>}
      <button onClick={() => faqClick(29)} className="btn">
        <p>{icon(29)}</p>
        <p>How do you figure that Trump won by 78k votes in 2016?</p>
      </button>
      {openFaq === 29 && <p>{answers[29]}</p>}
      <button onClick={() => faqClick(30)} className="btn">
        <p>{icon(30)}</p>
        <p>Can I join someone's crew after I've joined Landslide?</p>
      </button>
      {openFaq === 30 && <p>{answers[30]}</p>}
      <button onClick={() => faqClick(31)} className="btn">
        <p>{icon(31)}</p>
        <p>What if I invited someone and they don't show as pending?</p>
      </button>
      {openFaq === 31 && <p>{answers[31]}</p>}
      <button onClick={() => faqClick(32)} className="btn">
        <p>{icon(32)}</p>
        <p>Can I use Landslide for my campaign for city council (or congress, or…)?</p>
      </button>
      {openFaq === 32 && <p>{answers[32]}</p>}
      <button onClick={() => faqClick(33)} className="btn">
        <p>{icon(33)}</p>
        <p>Is vote-by-mail safe? How do you know?</p>
      </button>
      {openFaq === 33 && <p>{answers[33]}</p>}
      <button onClick={() => faqClick(34)} className="btn">
        <p>{icon(34)}</p>
        <p>Michelle Obama said I need to send my absentee ballot in as soon as possible, when do you suggest?</p>
      </button>
      {openFaq === 34 && <p>{answers[34]}</p>}
      <button onClick={() => faqClick(35)} className="btn">
        <p>{icon(35)}</p>
        <p>Landslide says I’m not registered but I know I am.</p>
      </button>
      {openFaq === 35 && <p>{answers[35]}</p>}
      <button onClick={() => faqClick(36)} className="btn">
        <p>{icon(36)}</p>
        <p>How do you know people who sign up are going to vote?</p>
      </button>
      {openFaq === 36 && <p>{answers[36]}</p>}
      <button onClick={() => faqClick(37)} className="btn">
        <p>{icon(37)}</p>
        <p>What validation do you have that Landslide can get more people to vote?</p>
      </button>
      {openFaq === 37 && <p>{answers[37]}</p>}
      <button onClick={() => faqClick(38)} className="btn">
        <p>{icon(38)}</p>
        <p>How can I set up my own vote challenge?</p>
      </button>
      {openFaq === 38 && <p>{answers[38]}</p>}
      <button onClick={() => faqClick(39)} className="btn">
        <p>{icon(39)}</p>
        <p>What if I want to do more?</p>
      </button>
      {openFaq === 39 && <p>{answers[39]}</p>}
      <button onClick={() => faqClick(40)} className="btn">
        <p>{icon(40)}</p>
        <p>How do I add Landslide to my homescreen?</p>
      </button>
      {openFaq === 40 && <p>{answers[40]}</p>}
    </div>
  )
}

const answers = [
  <p>
    Landslide is about working with your friends to change the world; in that vein, it was founded by two friends,{' '}
    <a href="https://www.linkedin.com/in/jaredkrause" target="_blank" rel="noreferrer">
      Jared Krause
    </a>{' '}
    and{' '}
    <a href="https://www.linkedin.com/in/kipchoge/" target="_blank" rel="noreferrer">
      Kipchoge Spencer
    </a>
    , who in turn recruited their friends to help. It is designed and built in the US by a team of about 15 progressive volunteers. Another Trump
    presidency represents an existential threat to democracy, the planet, and the essential well being of the people we love, so we decided to drop
    our regular lives and do everything we could to get rid of him. Led by a group of activists, creatives and seasoned technology entrepreneurs
    who’ve built apps used daily by tens of millions of users, Landslide brings the best of political organizing, product design, viral content
    creation and direct to consumer marketing into one place for the first time. Our vision extends past the election, and we hope to make Landslide
    into an enduring piece of the progressive landscape.
  </p>,
  <p>
    By voting early in person, you can avoid crowded polling places on November 3rd. It’s the best way to ensure your vote is received on time and
    processed securely. Even if you have already applied for your mail-in ballot, most states allow you to vote early in person.{' '}
    <span>Here are six more reasons to vote early: </span>
    <ol>
      <li>
        If you find polling station problems, there’s time to{' '}
        <a href="https://survey123.arcgis.com/share/6a2e4700a39d408d94f4d7bbb8e6a05a" target="_blank" rel="noreferrer">
          report them
        </a>{' '}
        and try again{' '}
      </li>
      <li>If there are problems with your registration, there’s time to fix them </li>
      <li>Voting early in person means you can vote on your own time; what if unexpected personal reasons prevent you from voting on Nov 3?</li>
      <li>Eases the burden on counting mail ballots</li>
      <li>Enhances safety at the polls and spreads out voters</li>
      <li>Once you vote early you can then focus on getting out your friends who haven’t </li>
    </ol>
  </p>,
  <p>
    In-person votes get counted earlier, and we need as many Democratic votes as possible to be counted by Election Night. Consider the scenario
    Donald Trump is planning on, the so-called “Red Mirage.” On Election Night, because more Trump voters voted in person, Trump appears to win—that’s
    the mirage part. But in the following week, millions of mail-in ballots from Covid-conscious citizens come in, and the election swings WILDLY
    toward a victory for Biden. <br />
    <br />
    This is the outcome many experts are predicting. And Trump’s already told us how it’ll play out. He’ll fight—in court and, through proxies, on the
    street—to cast doubt on the election and claim victory based solely on the votes that come in on November 3rd. Trump has spent months building a
    false narrative around voter fraud—which statistically speaking barely exists—to lay the groundwork of confusion and chaos necessary to disrupt
    and delegitimize the mail-in ballot count. <br />
    <br />
    Trump has done this precisely because he knows what we do—more than twice as many Americans plan to vote by mail this year than they did in 2016.
    And right now, in key battlegrounds states like Florida, almost twice as many Democrats have requested absentee ballots as Republicans. If Biden
    supporters follow Michelle Obama’s advice to grab our comfortable shoes, go get in line and vote in-person and early whenever possible, Biden will
    be the Election Night victor and there will be no Red Mirage.
  </p>,
  <p>
    A handful of states don’t have early, in-person voting AND don’t start counting early mail-in ballots until Election Day or a couple of days
    before. Voting early by mail/absentee ballot in these states will actually contribute to the Red Mirage: CT, NH, PA, MS and SC.
  </p>,
  <p>
    By all and any means, vote! If you can’t vote early, vote on election day; if you can’t vote in person, vote by mail. Your vote will still count!
  </p>,
  <p>
    States are taking real precautions to ensure voting is safe -- including ensuring social distancing, masks, and regular disinfecting. Voting early
    in person is the best way to ensure you, your family, and your friends’ votes are safe and secure. Dr. Fauci says “if you go and wear a mask, if
    you observe the physical distancing” you should be able to vote safely. He also addresses at-risk people: “Obviously, if you’re a person who is
    compromised physically or otherwise, you don’t want to take the chance.”
  </p>,
  <p>
    The central premise of this campaign is that{' '}
    <span>
      it really,{' '}
      <a
        href="https://www.axios.com/bloomberg-group-trump-election-night-scenarios-a554e8f5-9702-437e-ae75-d2be478d42bb.html?fbclid=IwAR3NVk_WwLZfOYGD1n80ACqF9NxU8XRq1hYnvftkivceDljGo3gUwxkkwf4"
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        really matters
      </a>{' '}
      who is ahead on Election Night.
    </span>{' '}
    Because most states don’t begin counting mail-in ballots until Election Day—even if they’re mailed or dropped long in advance—and because it takes
    much longer to process mail-in ballots (they have to be manually signature verified one by one) than in-person votes, and because mail-in ballots
    are more likely to be rejected, we believe every Biden supporter who can safely vote in-person should do so.{' '}
    <span>If you can vote in person and early, that’s even better</span> because it eases crowding and gives you time to correct any issues that may
    arise when you try to vote.{' '}
  </p>,
  <p>
    If you are able to safely vote in-person, in <span>most</span> states that’s the best way to guarantee that your vote will be counted by Election
    Night. In these states, if you aren’t able to vote in person, for personal or health reasons or because your state doesn’t offer in-person voting,
    your next best bet is voting by mail or dropbox asap. In other states,{' '}
    <a
      href="https://www.ncsl.org/research/elections-and-campaigns/absentee-and-mail-voting-policies-in-effect-for-the-2020-election.aspx"
      target="_blank"
      rel="noreferrer"
    >
      early mail-in votes begin to be tabulated
    </a>{' '}
    as soon as they are received, and voting early by mail has all of the “vote early in person” advantages plus you get to do it from home. In any
    case, vote as soon as you are able and attempt to allow at least 7 days for your ballot to arrive by USPS. Ballot arrival deadlines and
    state-based voting advice can be found within Landslide.
  </p>,
  <p>
    If you have received your mail-in ballot already, most states will allow you to bring it to the polls and exchange it for a “live” ballot. If you
    haven’t received it, or have misplaced it or already returned it, some states will still allow you to vote a live ballot in person, but many
    states will only allow you to vote a provisional ballot at the polls. Provisional ballots are generally counted after mail-in ballots. Landslide
    gives specific guidance about how your state handles this issue after you’ve filled in your zip code.
  </p>,
  <p>
    No. Early in-person absentee voting means you can deposit a filled out mail-in/absentee ballot at a voting location before Nov 3. But, depending
    on your state, tabulation of these ballots might not begin until Nov 3, and in this case won’t be complete by the night of Nov 3. Early in-person
    voting, on the other hand, uses the same live ballots that are used on Election Day and these ballots will normally be tabulated by Election
    Night. Landslide’s voting advice takes into account when your state begins tabulation.
  </p>,
  <p>
    Yes!
    <br />
    <br />
    VERIFY (via Landslide, your local election office, or vote411.org)
    <ul>
      <li>Your registration</li>
      <li>Polling location</li>
      <li>Exact type of ID needed</li>
    </ul>
    BRING
    <ul>
      <li>Correct ID</li>
      <li>Mask</li>
      <li>Proof of Registration (card or photo of confirmation)</li>
      <li>
        Your completed Sample Ballot (often available on your county election website or via{' '}
        <a target="_blank" rel="noreferrer" href="https://ballotpedia.org/Main_Page">
          Ballotpedia
        </a>
        ){' '}
      </li>
      <li>Provisions for waiting in line—folding chair, water, sunscreen, snacks</li>
    </ul>
    ASK TO HAND MARK YOUR BALLOT <br />
    Ask to mark a paper ballot by hand with a pen, rather than with a touchscreen. Even some touchscreen polling places will let you HAND MARK a paper
    ballot at the polls if you ask.{' '}
    <a target="_blank" rel="noreferrer" href="https://www.nybooks.com/daily/2019/12/17/how-new-voting-machines-could-hack-our-democracy/">
      Why it matters
    </a>
    .
    <br />
    <br />
    IF YOU MUST VOTE ON A TOUCHSCREEN <br />
    Compare the paper printout, if any, to your completed SAMPLE BALLOT to verify the machine didn’t change or delete your selections.
    <br />
    <br />
    IF CHALLENGED, VOTE PROVISIONALLY <br />
    Yes, provisional ballots are counted!
    <br />
    <br />
    VOLUNTEER <br />
    <ul>
      <li>
        As a poll worker or as a poll watcher—contact your state or county political party or{' '}
        <a href="https://www.workelections.com/" target="_blank" rel="noreferrer">
          workelections.org
        </a>
      </li>{' '}
      <li>
        To photograph precinct totals after polls close on election night. Sign up via Photo Finish at{' '}
        <a href="https://www.protectourvotes.com/" target="_blank" rel="noreferrer">
          ProtectOurVotes.com
        </a>
      </li>{' '}
      <li>
        For more election-security volunteer opportunities, see{' '}
        <a href="https://www.scrutineerscommunity.org/" target="_blank" rel="noreferrer">
          Scrutineers.org
        </a>
      </li>
    </ul>
    GET HELP WITH VOTING PROBLEMS & QUESTIONS Call 1-866-OUR-VOTE
    <br />
    <br />
    REPORT PROBLEMS <br />
    <ul>
      <li>Alert poll worker</li>
      <li>Alert others in line</li>
      <li>
        Report via the{' '}
        <a
          href="https://survey123.arcgis.com/share/6a2e4700a39d408d94f4d7bbb8e6a05a?portalUrl=https://DemLabs.maps.arcgis.com"
          target="_blank"
          rel="noreferrer"
        >
          See Something, Say Something
        </a>{' '}
        form{' '}
      </li>
      <li>Post on social media & contact local news</li>
    </ul>
    (Thanks to election security advocate, writer and attorney,{' '}
    <a href="https://twitter.com/jennycohn1" target="_blank" rel="noreferrer">
      Jennifer Cohn
    </a>{' '}
    for voting advice.)
  </p>,
  <ol>
    <li>
      Submit your ballot in time! Experts say the most common reason absentee ballots are rejected is that they aren't received by the deadline. Our
      in-app guidance will help you be certain of the relevant deadlines for your state.
    </li>
    <li>
      Follow the instructions very carefully: If it says sign the back of your envelope, do that. If it says use black or blue ink, do it. If it says
      fill in the oval, fill in the oval. If it says use a secrecy envelope or get a witness to sign, do those things.
    </li>
    <li>
      Pay close attention to your signature. It should match the one on your voter registration card or drivers license. If you’re young or Black, pay
      extra close attention: In the recent Florida primary, only 1.3% of the total number of mailed ballots were rejected, yet 3.6% ballots cast by
      18-to-29-year-olds were rejected, and 2.3% of Black voters’ mailed ballots were not counted.
    </li>
    <li>
      If it’s fewer than 10 days before the election, try to drop it off in a ballot drop-off box, if possible. Otherwise, use the USPS and make sure
      you affix a stamp if required.
    </li>
    <li>
      Track your ballot to make sure it arrives and everything’s ok. If your state offers it, our in-app voter info tool will guide you. One of the
      reasons to vote early is so you’ll have time to fix any problems that might arise, but you’ll have to track your ballot in order to know.
    </li>
  </ol>,
  <p>
    Whether it’s broken machines, unsafe conditions, intimidating “poll watchers,” or anything else that makes voting difficult, dangerous or
    impossible, you can quickly report an issue using the{' '}
    <a
      href="https://survey123.arcgis.com/share/6a2e4700a39d408d94f4d7bbb8e6a05a?portalUrl=https://DemLabs.maps.arcgis.com"
      target="_blank"
      rel="noreferrer"
    >
      See Something, Say Something
    </a>{' '}
    form to alert the press. If you experience a problem while voting or have questions about the voting process—from finding your polling place to
    reporting problems with the election system—please call 866-OUR-VOTE or text OUR VOTE to 97779 to get free support from the nation’s largest
    nonpartisan voter protection coalition.
  </p>,
  <p>
    We’ve had a small amount of funding from friends and family and have recently begun accepting donations from supporters and users. We are
    extremely cost effective and our unique set of relational organizing methods will enable us to deliver new votes for 5-20x less than other
    campaigning tactics. As we’ve grown, we’ve started to run into significant operational costs for things like sending text messages and data
    hosting. Our mission is to serve and empower the People, and we don’t take money from any entity that might jeopardize that. In that vein, we
    haven’t and won’t accept any funding that gives any external person power over Landslide, our choice of campaigns, selection of candidates, etc.
    You can help us remain independent by directly supporting our work with a conventional{' '}
    <a href="https://secure.actblue.com/donate/landslide2020" target="_blank" rel="noreferrer">
      donation via ActBlue
    </a>
    , or{' '}
    <a href="http://give.landslide2020.org" target="_blank" rel="noreferrer">
      a micro donation that’s triggered every time the GOP assaults voting rights
    </a>
    .
  </p>,
  'Three things: (1) make sure you stay up to date with your own voting preparation (registration, absentee, early voting, etc) on Landslide. We try to make it a one stop shop for all that. (2) Keep recruiting friends. The more you get, the bigger your landslide; aim to get at least 5 active friends in your crew. (3) Nudge your friends to (a) make sure they’re staying on track with voting and (b) building their own landslides.',
  'Yes. All the data we collect from you is used to provide you the Landslide service. The data will never be sold, and it will never be used to contact or advertise to your friends.',
  <p>
    A lot of people think this, but research finds{' '}
    <a
      href="https://static1.squarespace.com/static/5af592de297114493d2c8966/t/5f04a71bc9927531ccc4e190/1594140458264/votetripling-reach-study-newer"
      target="_blank"
      rel="noreferrer"
    >
      almost no correlation
    </a>{' '}
    between how likely to vote{' '}
    <a href="https://youtu.be/8xSPuNus5Og?t=586" target="_blank" rel="noreferrer">
      you think your friends are
    </a>{' '}
    and how likely they are in reality. <span>In fact, If you think your friends are “likely” to vote, you’d be wrong 50% of the time.</span> If you
    think your friends are “extremely likely” to vote, you'd be wrong 84% of the time.{' '}
    <span>
      Even among your friends you think are “extremely likely” to vote in the critical election this November, research reveals that a quarter of them
      won’t without your intervention.
    </span>{' '}
    The research also suggests that voting is a social behavior: friends who know their friends vote are more likely to vote. But, how do you know if
    your friends will actually vote? Landslide provides visibility and help before the election and it's just enough to get folks who might not
    otherwise vote to show up at the polls.
  </p>,
  `Truth is, sending SMS messages is really expensive and we haven’t taken any $ from billionaires to fund this. Between now and the election, we'll send you around 5-10 messages, mainly just to help you be more effective at recruiting friends, make sure you don’t miss voting deadlines and show you the exact number of people who are committing, registering and voting because of you.`,
  `Nope. It takes about 30 seconds to sign up, and in 2 minutes you can invite a handful of friends. Of course, the more time you spend, the bigger your landslide will grow and the more impact you’ll have. In other words, it doesn’t have to be time consuming, but we hope it is.`,
  `When someone signs up for Landslide with your link we count them toward your "Power Score", which is the exact number of people who've signed up because of you. When the people you recruit (or the people they recruit) invite more people, we count them towards your Score, too. A few days after inviting your friends, they will have recruited 20-30 more people. A week after that, it's 70-100. All with a few minutes of effort. People who spend more time or have a big following can easily turnout hundreds or thousands of voters—even enough to swing an entire state.`,
  `Alabama, Alaska, Arizona, Colorado, Florida, Georgia, Iowa, Kansas, Kentucky, Louisiana, Maine, Michigan, Minnesota, Mississippi, Montana, Nevada, New Hampshire, North Carolina, Ohio, Pennsylvania, South Carolina, Texas and Wisconsin.`,
  `We chose target states that were close last election and winnable this time for either the presidential contest, the Senate race or both. By winnable, we don’t mean winnable if we can convince enough Trump supporters to change their minds. We mean there are way more than enough people who lean Democrat but didn’t vote last time; our job is just to convince their friends to convince them to vote in November (or better yet, earlier).`,
  `No problem, most people don’t. First, there are literally thousands of other elections that matter this year. You voting for progressive and Democratic candidates and ideas all the way down the ballot makes a huge difference. You recruiting friends to do the same makes a landslide of difference—regardless of the state any of you are in. Second, you can easily recruit friends who do live in target states.`,
  <p>
    Three ways. (1) Message everyone in your crew with the list of target states. Ask them to think about filling their crews with friends they have
    in those states. (2) Write every target state on a piece of paper in a list down the left side. Next to each state, write the name of any friend
    you can think of who lives there and supports progressives or Democrats. Reach out personally to each one and invite them to your crew. (3) Do a
    Facebook search for each state, for example: “friends who live in Georgia.” For best results, message each of these friends privately with your
    crew link. Then make a public post and tag each friend and say something like, “Hey Georgia friends, you live in one of the crucial states that
    will decide the election. I’m tapping you for my Landslide crew. Hey everyone else, if you know anyone who lives in Georgia and leans Democrat,
    join my Landslide by clicking the link and then invite those Georgia friends to your crew.” Remember to put your public link in the post. You can
    also look for state-by-state graphics in the{' '}
    <a href="https://www.dropbox.com/sh/htsej2o077eh4q8/AAAvCvMCToOyghjU57BS3u3La?dl=0" target="_blank" rel="noreferrer">
      Landslide social media toolbox
    </a>
    .
  </p>,
  'No.',
  `On your crew screen, when you tap one of the invite buttons, it gives you what we call your private or crew link. It’s for inviting people to your crew. You can invite whomever you want to your crew, but we designed the crew experience with the idea that it would be a group of friends, small enough so that you maintain a feeling of mutual accountability and no one slips into anonymity. When people join Landslide through your crew link, you are given the opportunity to confirm them into your crew. If your crew link were to escape into the wild (when a friend mistakenly shares it, for example) the confirmation step keeps your crew within your control. But what if you want to invite a wider circle to Landslide, including folks you don’t even know? You probably don’t want them in your crew. Instead, you can use the public link on your power screen. This is the link that you’d put in your Instagram profile or post on Facebook or share out in a newsletter. Whichever link you use, whoever uses it to join will become a node in your landslide and all of their invitees will add to your power scores.`,
  `Yes. Every time you tap the invite button it will generate a new, unique crew invite. This allows you to associate a certain person or group of people with that invite, and when one of them joins the pending invite disappears. But, if you don’t care about keeping track of whom you invited, you can just copy/paste the same invite to as many people as you like, over and over, or send it to a bunch of people at the same time.`,
  <p>
    We think invitations work best when they’re personal rather than transactional. It helps to talk about why you care about this election and how
    you think Landslide can help. If you can ask someone in person to join your crew, that’s great, but of course a personal text message can work,
    too. Invite people who you will be happy to nudge because it’s all about the nudge! If you send one message without a response, consider sending
    another with a list of all the target states. This can be a way for your friend to engage in thinking about where they might have friends to
    invite. If you happen to have some young people in your vicinity, invite them and have them invite their voting age friends. We also post invite
    tips on{' '}
    <a href="https://www.facebook.com/jointhelandslide" target="_blank" rel="noreferrer">
      our Facebook page
    </a>{' '}
    and are happy to learn about yours, too. Please check in and tell us what’s working.
    <br />
    <br />
    <p>
      Recently, a user reported sending a mass email to 70 of his friends with the following message. Eleven of the recipients signed up immediately:{' '}
    </p>
    <br />
    <p>
      Subject: Join me in get-out-the-vote effort <br />
      <br />
      Hi All,
      <br /> I’ve joined Landslide- a tool that is going viral now to win the election. You simply <br />
      invite friends and family to join you in committing to vote. <br />
      <br />
      The goal of course is to win in November and fight against voter suppression. It's super easy- takes about 2 minutes. <br />
      <br />
      Join me here: [crew link] <br />
      <br />
      Thank you!
    </p>
  </p>,
  <p>
    Yes!{' '}
    <a href="https://youtu.be/7NgLKECRtJ4" target="_blank" rel="noreferrer">
      https://youtu.be/7NgLKECRtJ4
    </a>
  </p>,
  `Even though Clinton won the popular vote by almost 3 million votes, Trump won the Electoral College vote 304-227. If Hillary had won Michigan, Wisconsin and Pennsylvania, she would have won the Electoral College, too. Her combined deficit in those three states was just 77,744 votes.`,
  `Not yet, but someday we’ll add adoption. Because everyone should know what it feels like to be in someone else’s crew!`,
  `As long as they follow your link to join Landslide, they’ll show up on your crew page for you to confirm.`,
  `Yes, Landslide is a free tool. At this time we aren’t coordinating with or offering any services to campaigns. But, as long as you aren’t a Republican or equivalent right-wing nutso you can use it. Landslide is easier to use in a campaign setting than most other tools because there’s nothing to integrate. Simply create an account for your campaign (put the campaign name in the first name field, e.g., “Jones for Congress,” and the + symbol in the last name field) then share your public/power link with your supporters. Ask them to recruit friends in your voting district. If you like, you can add campaign staff and volunteers to your crew for a friendly competition to see who can get the most voters.`,
  <p>
    Yes. Several states have been offering vote by mail to all voters for years with little problem and great success. Take a state like Washington,
    for instance. They’ve had{' '}
    <a
      href="https://www.npr.org/2020/05/28/864410852/lessons-to-learn-from-washingtons-decades-long-experience-of-mail-in-voting"
      target="_blank"
      rel="noreferrer"
    >
      vote-by-mail for decades
    </a>
    , have consistently higher turnout than almost every other state, and have very few problems. We encourage you to follow Landslide’s thoroughly
    researched guidance regarding when and how to vote in your state. We’ll also help you get set up to electronically track your ballot if you live
    in one of the 39 states that offer it.
  </p>,
  `We agree. Also, we help you know when the deadlines are and help you help your friends make sure they’re on track to vote on time, too.`,
  `We do everything we can to deliver the most correct voter information possible, but you might very well know better than we do. We source our data from the most up-to-date public sources, but there can nonetheless be time delays between your local election jurisdiction and the state-level reporting. If you have any reason to doubt your voter registration status, we suggest you contact your local registrar of voters to triple check. Unless you live in North Dakota, their contact info is available on your Landslide voter info screen.`,
  `In multiple large, well-documented studies on voter behavior, friends reaching out increases the probability that their friends will vote. The sum total of all the interventions that Landslide makes easy suggests that users will be 20% more likely to vote than non-users. That might not sound like much, but 20% is enough to swing almost every single election in the country.`,
  <p>
    Research shows that voter mobilization is hard—
    <a
      href="https://www.dailykos.com/stories/2018/7/1/1777180/-Flip-Congress-2018-Practical-Advice-for-Elections-Activists"
      target="_blank"
      rel="noreferrer"
    >
      robocalls and mass mailings are generally found to be ineffective
    </a>
    —but some techniques CAN break through the noise. For example:
    <br />
    <br />
    <ul>
      <li>
        Personal emails appear to have a strong get out the vote effect on acquaintances—creating a{' '}
        <a href="https://www.epolitics.com/2017/09/28/research-say-friend-friend-outreach-politics/" target="_blank" rel="noreferrer">
          12-15% bump
        </a>{' '}
        in one study
      </li>
      <li>
        Personal phone calls can prompt a{' '}
        <a href="https://www.poverty-action.org/sites/default/files/publications/GOTVphonecalls_Ha_Karlan_full.pdf" target="_blank" rel="noreferrer">
          3.3% bump
        </a>
      </li>
      <li>
        Text messages to recipients who previously agreed to receive this type of reminder raised voting likelihood by approximately{' '}
        <a href="https://www.tandfonline.com/doi/abs/10.1080/17457289.2012.728223" target="_blank" rel="noreferrer">
          4.1%
        </a>
      </li>
      <li>
        Calls simply asking where and when recipients intended to vote increased turnout by as much as{' '}
        <a
          href="https://www.gsb.stanford.edu/faculty-research/centers-initiatives/csi/sites/csi.gsb.stanford.edu/files/T.Rogers%20-%20Do%20You%20Have%20a%20Voting%20Plan.pdf"
          target="_blank"
          rel="noreferrer"
        >
          0.9%
        </a>
      </li>
      <li>
        An email from a friend was determined to{' '}
        <a
          href="https://d31ztdpy2vop11.cloudfront.net/website/documents/case_studies/Cormack-2019-Politics_&_Policy.pdf"
          target="_blank"
          rel="noreferrer"
        >
          increase turnout by 20+ points
        </a>{' '}
        in a recent low information election
      </li>
      <li>
        A study of phone or in-person contact plus getting voters to sign a pledge card{' '}
        <a
          href="https://ccis.ucsd.edu/_files/Final_Report_Testing_New_Technologies_in_Mobilizing_Voters_of_Color.pdf"
          target="_blank"
          rel="noreferrer"
        >
          increased turnout by 7.9%
        </a>
      </li>
      <li>Possession of an absentee ballot itself can boost turnout by 3 points</li>
      <li>
        When you encourage friends to vote you become{' '}
        <a href="https://www.votetripling.org/evidence#anchor5" target="_blank" rel="noreferrer">
          more likely to vote
        </a>
        , too
      </li>
      <li>
        Voting is contagious; the more people in your network who vote, the more likely you are to vote. (Christakis and Fowler 2009. Connected: The
        Surprising Power of Our Social Networks and How They Shape Our Lives)
      </li>
      <li>
        A 2008 field experiment undertaken by three of the most cited GOTV researchers of our time, involving several hundred thousand registered
        voters, promised to publicize voters’ turnout to their household or their neighbors. The findings of an{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.cambridge.org/core/journals/american-political-science-review/article/social-pressure-and-voter-turnout-evidence-from-a-largescale-field-experiment/11E84AF4C0B7FBD1D20C855972C2C3EB"
        >
          8% turnout increase{' '}
        </a>
        “demonstrate the profound importance of social pressure as an inducement to political participation.”
      </li>
      <li>
        These same researchers did another large scale experiment in 2019 that showed a{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://static1.squarespace.com/static/5c8176839b8fe8489c416c7d/t/5e41b08ee4ea721156a69a0c/1581363342869/Columbia+Turnout+Nation+report+7+Feb+2020+Final.pdf"
        >
          13.2% turnout bump
        </a>{' '}
        using a “captain” model of promoting voter turnout, which focuses on contacts between people who belong to the same social network. “The
        substantive magnitude of this [treatment] is extraordinary, exceeding estimates from any other randomized trial on voter turnout,” note the
        authors. Notably, the <span>captains in this study who were regularly nudged by a captain of their own produced a turnout bump of 17.1%</span>{' '}
        in their crews, nearly twice that of captains who weren’t regularly nudged.
      </li>
    </ul>
    Meanwhile, research on social networks and interpersonal influence suggests that{' '}
    <a target="_blank" rel="noreferrer" href="https://www.sciencenews.org/blog/scicurious/why-people-dont-vote-and-what-do-about-it">
      personalized messages delivered by *personal* contacts{' '}
    </a>
    may be more effective than personalized messages delivered by people outside a voter’s social network. Landslide essentially aims to hit all of
    these already-proven friends-nudging-friends mobilization tactics—pledge, personal reminders by voice/ping/sms from multiple friends, identify
    your polling place and time, get an absentee ballot, etc. But, we also add in the real-time accountability piece. This is novel in the field, and
    significant because:
    <br />
    <br />
    <ul>
      <li>
        People are up to{' '}
        <a target="_blank" rel="noreferrer" href="https://www.seattletimes.com/business/peer-pressure-applied-well-can-boost-your-financial-health/">
          2x more likely
        </a>{' '}
        to follow through on a personal commitment when a someone else is paying attention
      </li>
    </ul>
  </p>,
  <p>
    Easy. Three steps. <span>First</span>, talk to or message 5 friends. Ask if they’d be willing to join your crew and see who can get the most votes
    (if you’re feeling competitive) or see how many you can all get together. Msg them your crew link privately <span>Second</span>: tag them in a
    social media post asking them to do two things: join your challenge and start their own, too. Post a photo of yourself holding up 5 fingers or a
    video of you counting off the five friends by name, and saying what you’re challenging them to do and why. <span>Third</span>, post your power
    score screen every week on social. Tag the friends in your challenge and get them to post theirs and tag the others in the challenge. Note: the
    friends in your challenge will be in your crew, but not everyone in your crew has to be in the challenge.
  </p>,
  <ul>
    <li>
      Add something like this to your social media profile or email signature (using your unique power link): End Voter Suppression. End the Trump
      Era. Start Your Landslide at Landslide2020.org
    </li>
    <li>
      Support Landslide by{' '}
      <a target="_blank" rel="noreferrer" href="https://give.landslide2020.org/">
        donating some pennies every time Trump or the GOP tries to suppress the vote.
      </a>
    </li>
    <li>Do a vote challenge. See above.</li>
  </ul>,
  <p>
    <span>Android Chrome</span>
    <br />
    Open Chrome and navigate to Landslide2020.org. Open Chrome’s Settings menu by tapping the three vertical dots found in the top-right corner.
    Select the “Add To Home Screen” option.
    <br />
    <br />
    <span>Android Firefox</span>
    <br />
    Open the Mozilla Firefox app and navigate to Landslide2020.org. Touch and hold the website’s URL in the address bar until a pop-up menu appears.
    Select the “Add Page Shortcut” option.
    <br />
    <br />
    <span>iPhone Safari</span>
    <br />
    Open Safari and navigate to Landslide2020.org. Once there, tap once near the bottom of the screen to bring up the navigation toolbar. Then tap the
    Share icon (the rectangle with an upward arrow). Scroll through the share menu and tap “Add to Home Screen.” Then use the Add to Home Screen menu
    to name your new icon. Then tap “Add” in the top-right corner of the box.
  </p>,
]
